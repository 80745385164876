import React from "react";
import { Box, Button, Heading, Text, VStack } from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";

const ForbiddenPage = () => {
  const navigate = useNavigate();

  const handleBackHome = () => {
    navigate("/");
  };

  return (
    <Box minH="100vh" display="flex" alignItems="center" justifyContent="center" bg="white" p={4}>
      <VStack spacing={6} textAlign="center">
        <Icon icon={"solar:shield-star-bold-duotone"} fontSize={50} color="orange" />
        <Heading size="2xl" color="red.500">
          403
        </Heading>
        <Heading size="sm" fontWeight={"semibold"} color="red.500" mt={-5}>
          Forbidden
        </Heading>
        <Text fontSize="sm" color="gray.700">
          Oops! You don’t have permission to access this page.
        </Text>
        <Button mt={10} colorScheme="teal" onClick={handleBackHome} size="lg" variant="solid" bg="blackAlpha.800">
          Go Back to Home
        </Button>
      </VStack>
    </Box>
  );
};

export default ForbiddenPage;
